@import '../../common/mixins';
@import '../../common/style';

.wave {
  position: absolute;
  height: 100%;
  width: 100800px; // Best estimate for a necessary high-bound for realistic usage (multiple of the image size)
  left: -50400px; // Start in the middle of the wave image to be able to go both ways

  z-index: 1;

  @include transition(transform $animation-duration ease-in-out);

  display: none;

  @include media-breakpoint-up(lg) {
    display: unset;
  }
}

.wave--mobile {
  position: absolute;
  width: 100%;
  height: 30%;
  background-size: cover;
  z-index: 1;

  // Adjust the height for larger mobile screens to see more of the wave
  @include media-breakpoint-up(md) {
    height: 50%;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}
