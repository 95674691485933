@import './common/style';
@import './common/mixins';
@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');

html {
  font-size: 16px;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  h1, h2, h3, h4, h5, h6, p, span, ul, li {
    font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;

    text-transform: none;
    font-style: normal;
  }
}

// Override defaults of Dexter's template that conflicts with the app
.fsPageTitle {
  display: none;
}

#fsPageBodyWrapper {
  padding: 0;
}

#fsPageBody {
  margin: 0;
  width: 100%;
  max-width: 100%;
}

.fsDiv {
  margin: 0 !important;
}

.app {
  background-color: $common-white-color;

  @include media-breakpoint-up(sm) {
    font-size: 16px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 20px;
  }

  margin: 0;
  font-size: 16px;

  h1, h2, h3, h4, h5, h6, p, span, ul, li {
    // Override default color just for our content
    color: $common-black-color;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1 {
    font-size: 1.6rem;
    font-weight: normal;
  }
  
  h2 {
    font-size: 1.4rem;
    font-weight: 500;
  }
  
  h3 {
    font-size: 1.3rem;
    font-weight: bold;
  }
  
  h4 {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  h5 {
    font-size: 1.1rem;
  }
  
  h6, p, ul, li, a {
    font-size: 1rem;
    font-weight: normal;
  }

  @include media-breakpoint-up(sm) {
    line-height: 1;

    h1 { font-size: 2.5rem; }

    h2 { font-size: 1.9rem; }

    h3 { font-size: 1.4rem; }

    h4 { font-size: 1.4rem; }

    h5 { font-size: 1.2rem; }

    h6, p, ul, li, a { font-size: 1.1rem; line-height: 1.5; }
  }

  @include media-breakpoint-up(lg) {
    h1 { font-size: 1.9rem; }

    h2 { font-size: 1.6rem; }

    h3 { font-size: 1.2rem; }

    h4 { font-size: 1.1rem; }

    h5 { font-size: 1rem; }

    h6, p, ul, li, a { font-size: 0.95rem; }
  }

  @media (min-width: 1366px) {
    h1 { font-size: 2.3rem; }

    h2 { font-size: 1.9rem; }

    h3 { font-size: 1.3rem; }

    h4 { font-size: 1.2rem; }

    h5 { font-size: 1.1rem; }

    h6, p, ul, li, a { font-size: 1rem; }
  }

  @media (min-width: 1920px) {
    h1 { font-size: 2.8rem; }

    h2 { font-size: 2.4rem; }

    h3 { font-size: 1.6rem; }

    h4 { font-size: 1.5rem; }

    h5 { font-size: 1.4rem; }

    h6, p, ul, li, a { font-size: 1.3rem; }
  }

  @media (min-width: 2560px) {
    h1 { font-size: 3.2rem; }

    h2 { font-size: 2.8rem; }

    h3 { font-size: 2.2rem; }

    h4 { font-size: 2rem; }

    h5 { font-size: 1.9rem; }

    h6, p, ul, li, a { font-size: 1.6rem; }
  }
}
