// For screen sizes
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px, // Desktop-version begins here
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

@import "~bootstrap-4-grid/scss/grid/functions";
@import "~bootstrap-4-grid/scss/grid/variables";
@import "~bootstrap-4-grid/scss/grid/mixins/breakpoints";
@import "~bootstrap-4-grid/scss/grid/mixins/grid-framework";
@import "~bootstrap-4-grid/scss/grid/mixins/grid";
@import "~bootstrap-4-grid/scss/grid/grid";
@import "~bootstrap-4-grid/scss/grid/utilities/display";
@import "~bootstrap-4-grid/scss/grid/utilities/flex";
@import "~bootstrap-4-grid/scss/grid/utilities/spacing";
@import "~bootstrap/scss/mixins/transition";

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin center-block() {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin custom-scroll-bar() {
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E0D6D7;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
