@import './common/mixins';
@import './common/style';

$vision-interactive-max-height: 800px;
$vision-interactive-max-width: 1700px;

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vision {
  @include media-breakpoint-up(lg) {
    height: 100vh;
    max-height: 1212px; // The point at which the helm svg does not shrink due to this max-height
  }
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: -1px; // Fix to remove gap between vision and transfer goals
}

.vision__interactive {
  display: none;

  // Desktop-version
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row;

    height: 66%;
    width: 100%;
    max-height: $vision-interactive-max-height;
    max-width: $vision-interactive-max-width;  
  }
}

.vision--mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  // Desktop version
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.waves {
  flex: 1 1 auto;
  width: 100%;
  overflow: hidden;
  position: relative;

  height: 200px;

  @include media-breakpoint-up(lg) {
    height: unset;
  }
}

.vision-footer {
  height: 100%;
  min-height: 160px;
  width: 100%;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;

  margin-top: -4%;

  // Position the footer text
  @include media-breakpoint-up(md) {
    margin-top: unset;
    padding-top: 3%;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 5%;
  }

  @media (min-width: 1136px) {
    padding-top: 5%;
  }

  @media (min-width: 1920px) {
    padding-top: 0;
  }
}

.vision-footer__text {
  color: #FFFFFF !important;
  text-align: center;
  margin: 0 5%;

  font-size: 1.5rem !important;
  margin-top: 15%;

  @include media-breakpoint-up(sm) {
    margin-top: 7%;
  }

  @include media-breakpoint-up(md) {
    margin-top: 3%;
    font-size: 1.8rem !important;
  }

  @include media-breakpoint-up(lg) {
    margin-top: unset;
    font-size: 1.7rem !important;
  }

  @include media-breakpoint-up(xl) {
    font-size: 2.3rem !important;
  }

  @media (min-width: 1536px) {
    font-size: 3rem !important;
  }

  @media (min-width: 1920px) {
    font-size: 3.4rem !important;
  }

  @media (min-width: 2560px) {
    font-size: 3.9rem !important;
  }
}

.waves__bottom-fill {
  position: absolute;
  height: 75%;

  width: 100%;
  bottom: 0;
  background-color: $common-blue-color;
  
  // Prevent overlap of waves
  @include media-breakpoint-up(lg) {
    height: 52%;
  }

  @media (min-width: 1920px) {
    height: 80%;
  }

  @media (min-width: 2560px) {
    height: 60%;
  }
}